<template>
  <div class="containertop-register">
    <nav-bar
      :title="'創建帳戶'"
      :isFixed="true"
      :goBack="goBack"
      :bgc="'background: rgba(224, 239, 201)'"
    ></nav-bar>
    <div class="bg"></div>
    <div
      v-if="isCaptchaNew"
      :class="['register-form', { headerTop: !isWeiXin() }]"
    >
      <van-form @submit="onSubmit">
        <div class="label" style="margin-top: 0;">
          {{ $t("診所名稱") }} <span class="verify">*</span>
        </div>
        <van-field v-model="params.userName" :placeholder="$t('診所名稱')" />
        <div class="error" v-if="verifyobj.userName">
          {{ $t("请填写診所名稱") }}
        </div>

        <div class="label">
          {{ $t("電子郵箱地址") }}
          <span class="verify">*</span>
        </div>
        <van-field
          v-model="params.email"
          type="text"
          :placeholder="$t('電子郵箱地址')"
        />

        <div class="error" v-if="verifyobj.email || verifyobj.emailregx">
          {{ $t("請輸入") }}
          {{ $t("有效電子郵箱地址") }}
        </div>

        <div class="label">{{ $t("密碼") }} <span class="verify">*</span></div>
        <van-field
          class="passwordhide"
          v-model="params.password"
          :type="isPassword ? 'password' : 'text'"
          @click-right-icon="isPassword = !isPassword"
          :placeholder="$t('請輸入6-15位含字母和數字密碼')"
        >
          <template #right-icon>
            <div>
              <img
                v-if="isPassword"
                style="width: 20px"
                src="@/assets/icons/eyes.png"
                alt=""
              />
              <img
                v-else
                style="width: 20px"
                src="@/assets/icons/eye-open.png"
                alt=""
              />
            </div>
          </template>
        </van-field>
        <div class="error" v-if="verifyobj.password || verifyobj.passwordregx">
          {{ $t("請輸入6-15位含字母和數字密碼") }}
        </div>

        <div class="label">{{ $t("姓名") }} <span class="verify">*</span></div>
        <van-field v-model="params.name" :placeholder="$t('姓名')" />
        <div class="error" v-if="verifyobj.name">
          {{ $t("請輸入") }} {{ $t("姓名") }}
        </div>

        <div class="label">
          {{ $t("診所地址") }} <span class="verify">*</span>
        </div>
        <div class="AddressList" @click="openAddressDialog">
          <div :style="selectTitle ? 'color:#1A1A1A;' : ''">
            {{ selectTitle ? selectTitle : $t("所在地區") }}
          </div>
          <img src="@/assets/icons/arrow-rightactive.png" alt="" />
        </div>
        <div class="error" v-if="verifyobj.province" style="margin-top: 6px;">
          {{ $t("請選擇") }} {{ $t("所在地區") }}
        </div>
        <van-field
          style="margin-bottom: 12px;margin-top: 12px;"
          v-model="params.street"
          :placeholder="$t('街道/門牌')"
        />
        <van-field v-model="params.address" :placeholder="$t('詳細診所地址')" />
        <div class="label">
          {{ $t("商業登記證") }} <span class="verify">*</span>
        </div>
        <van-uploader
          :max-count="1"
          :after-read="afterRead"
          :before-read="beforeRead"
          v-model="fileList"
          preview-size="100%"
        >
          <template #default>
            <div>
              <img
                v-if="langEnv == 2"
                src="@/assets/image/user/uploaden.png"
                alt=""
              />
              <img v-else src="@/assets/image/user/uploadzh.png" alt="" />
            </div>
          </template>
        </van-uploader>
        <div class="error" v-if="verifyobj.businessLicense">
          {{ $t("请填写") }} {{ $t("商業登記證") }}
        </div>

        <div style="text-align: right;margin-bottom: 16px;">
          <span class="verify">*</span> {{ $t("為必填内容") }}
        </div>
        <div class="agree">
          <van-checkbox
            checked-color="#90D12E"
            icon-size="16px"
            shape="square"
            v-model="checked"
            >{{ $t("我已閲讀并同意") }}
            <span
              class="agree-text"
              @click="$router.push(`/about?id=3&langEnv=${langEnv}`)"
              >《Rith {{ $t("隱私政策") }}》</span
            >{{ $t("和")
            }}<span
              class="agree-text"
              @click="$router.push(`/about?id=4&langEnv=${langEnv}`)"
              >《Rith {{ $t("服務協議") }}》</span
            ></van-checkbox
          >
        </div>
        <div style="margin: 12px 0;">
          <van-button color="#90D12E" block type="primary" native-type="submit">
            {{ $t("驗證郵箱號碼") }}
          </van-button>
        </div>
        <div style="text-align: center;color: #1A1A1A;" @click="gotoLogin">
          {{ $t("已有帳號？去登入") }}
        </div>
      </van-form>
    </div>
    <div class="email-CaptchaNew" v-else>
      <div class="CaptchaNew-title">
        {{ $t("爲了驗證您的電子郵件，我們將向您發送一個驗證碼，請查收后輸入") }}
      </div>
      <van-field
        v-model="CaptchaNewCode"
        :placeholder="$t('請輸入郵箱驗證碼')"
      />
      <div class="get-CaptchaNew" v-if="countDown !== 0">
        {{ countDown }}s，{{ $t("再次獲取驗證碼") }}
      </div>
      <div class="get-CaptchaNew" v-else @click="getEmailCode">
        {{ $t("獲取驗證碼") }}
      </div>
      <van-button
        :loading="loading"
        class="get-CaptchaNew-btn"
        @click="getSubmitRegister"
      >
        {{ $t("確認") }}</van-button
      >
    </div>
    <addressCascader
      :isShow="isShow"
      @handleClose="handleClose"
      @handleAreaCode="handleAreaCode"
    ></addressCascader>
    <van-dialog
      v-model:show="isRegister"
      title="提示"
      :showConfirmButton="false"
    >
      <div class="register-dialog">
        <div class="register-text">
          <div>{{ $t("注冊Rith賬號") }}，{{ $t("需閲讀並同意") }}</div>
          <div>
            <span @click="$router.push(`/about?id=3&langEnv=${langEnv}`)"
              >《Rith {{ $t("隱私政策") }}》</span
            >
            {{ $t("和")
            }}<span @click="$router.push(`/about?id=4&langEnv=${langEnv}`)"
              >《Rith {{ $t("服務協議") }}》</span
            >。
          </div>
        </div>
        <div class="register-btn">
          <div class="Cancel" @click="isRegister = false">
            {{ $t("取消") }}
          </div>
          <div class="Confirm" @click="gotoRegister">
            {{ $t("我已閲讀并同意") }}
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
import { getCaptchaNew, getvalidateEmail, getRegisterNew } from "@/api/user";
import { setToken } from "@/utils/token.js";
import mixins from "@/utils/mixins.js";
import { mapState } from "vuex";
export default {
  beforeRouteEnter(to, form, next) {
    if (form.path.includes("about")) {
      to.query.isCache = true;
    }
    next();
  },
  mixins: [mixins],
  data() {
    return {
      fileList: [],
      form: {
        userName: "",
        password: "",
        captcha: ""
      },
      params: {
        userName: "",
        password: "",
        email: "",
        platform: 4,
        name: "",
        address: "",
        captcha: "",
        province: "",
        city: "",
        district: "",
        street: "",
        businessLicense: "",
        invitationCode: ""
      },
      active: "1",
      isPassword: true,
      isEmail: false,
      isEyes: false,
      AddressList: [],
      isShow: false,
      selectTitle: "",
      checked: false,
      verifyobj: {
        userName: "",
        password: "",
        name: "",
        email: "",
        businessLicense: "",
        province: ""
      },
      verifyList: [],
      isRegister: false,
      isCaptchaNew: true,
      CaptchaNewCode: "",
      countDown: 60,
      loading: false,
      langEnv: ""
    };
  },
  computed: {
    ...mapState("user", ["registerForm", "cacheFileList"])
  },
  watch: {
    params: {
      deep: true,
      async handler(val) {
        try {
          this.$store.dispatch("user/getregisterForm", this.params);
          this.$store.dispatch("user/getFileList", this.fileList);
        } catch (error) {}
      }
    }
  },
  created() {
    console.log(this.$route);

    this.langEnv = window.localStorage.getItem("langEnv");
    if (this.$route.query.isCache) {
      this.params = { ...this.registerForm };
      this.fileList = this.cacheFileList;
      this.selectTitle = `${this.params.province.split("●")[1]||''}${
        this.params?.city?.split("●")[1]
          ? "/" + this.params?.city?.split("●")[1]
          : ""
      }${
        this.params?.district?.split("●")[1]
          ? "/" + this.params?.district?.split("●")[1]
          : ""
      }`;
    }
    console.log(this.fileList);

    // this.getAddressList();
  },

  methods: {
    goBack() {
      if (!this.isCaptchaNew) {
        this.isCaptchaNew = true;
      } else {
        this.$router.back();
      }
    },
    gotoRegister() {
      this.isRegister = false;
      this.checked = true;
    },
    beforeRead(file) {
      console.log(file);

      const maxSize = 5 * 1024 * 1024;
      // if (!file.type?.includes('image')) {
      //   this.$toast("請上傳圖片");
      //   return false;
      // }
      if (file.size > maxSize) {
        this.$toast(this.$t("文件體積過大"));
        return false;
      }
      return true;
    },
    validatoFormFormcheck(label) {
      if (!this.params[label]) {
        this.verifyList.push(label);
      } else {
        const index = this.verifyList.findIndex(item => item === label);
        if (index != -1) {
          this.verifyList.splice(index, 1);
        }
      }
    },
    validatoForm() {
      for (const key in this.verifyobj) {
        this.verifyobj[key] = "";
      }

      this.verifyList.forEach(item => {
        this.verifyobj[item] = true;
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    afterRead(file) {
      this.params.businessLicense = file.file;
      console.log(file);
      console.log(this.fileList);
    },
    handleAreaCode(data) {
      const areaList = data.filter(item => item.value !== "1");
      areaList.forEach((item, index) => {
        if (index === 0) {
          this.params.province = item.value + "●" + item.label;
        } else if (index === 1) {
          this.params.city = item.value + "●" + item.label;
        } else if (index === 2) {
          this.params.district = item.value + "●" + item.label;
        }
      });
      const res = areaList.map(items => items.label).join("/");
      this.selectTitle = res;
      console.log(this.params);
    },
    async getEmailCode() {
      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`;

      let jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);
      let dataToEncrypt = JSON.stringify({
        email: this.params.email,
        type: "register",
        captcha: ""
      });
      console.log(dataToEncrypt);
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      });
    },
    async onSubmit() {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const regx = /^(?=.*\d)(?=.*[a-zA-Z]).+$/;
      this.verifyList = [];
      if (!regxEmail.test(this.params.email)) {
        this.verifyobj["emailregx"] = true;
      } else {
        delete this.verifyobj["emailregx"];
      }
      if (!regx.test(this.params.password)) {
        this.verifyobj["passwordregx"] = true;
      } else {
        delete this.verifyobj["passwordregx"];
      }

      const keys = Object.keys(this.verifyobj);
      keys.forEach(item => {
        this.validatoFormFormcheck(item);
      });

      this.validatoForm();

      if (this.verifyList.length > 0) {
        return;
      }
      if (!this.checked) {
        this.isRegister = true;
        return;
      }
      const res = await getvalidateEmail({ email: this.params.email });
      if (res.resultID !== 1200) {
        // 邮箱已存在
        return this.$toast(res.message);
      } else {
        this.getEmailCode();
        this.isCaptchaNew = false;
        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(timer); // 清除定时器
            this.countDown = 0;
          }
        }, 1000);
      }

      console.log(this.verifyobj);
      console.log(this.verifyList);
    },
    async getSubmitRegister() {
      this.params.captcha = this.CaptchaNewCode;
      if (this.$route.query.invitationCode) {
        this.params.invitationCode = this.$route.query.invitationCode;
      }

      let formData = new FormData();
      Object.entries({ ...this.params }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // formData.append("businessLicense", this.params.businessLicense);
      this.loading = true;
      const res = await getRegisterNew(formData);

      if (res.resultID === 1200) {
        this.$toast(res.message);
        setToken(res.data.token);
        localStorage.setItem("userName", res.data.userName);
        localStorage.setItem("roles", res.data.roles);
        this.$router.push("/login");
        this.loading = false;
      }
    },
    openAddressDialog() {
      this.isShow = true;
    },
    handleClose(data) {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss">
.containertop-register {
  .email-CaptchaNew {
    padding: 40px 26px;
    .CaptchaNew-title {
      font-size: 15px;
      color: #666666;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .get-CaptchaNew {
      text-align: center;
      padding: 10px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #d2edab;
      color: #bce382;
      margin-bottom: 40px;
    }
    .get-CaptchaNew-btn {
      width: 100%;
      background: #90d12e;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
    }
  }
  .bg {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 210px;
    background: linear-gradient(
      180deg,
      rgba(144, 209, 46, 0.3) 0%,
      rgba(144, 209, 46, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
  }

  .logo {
    width: 166px;
    margin: 46px 0 40px 32px;
  }
  .register-form {
    padding: 24px;
    padding-bottom: 53px;
    .label {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 17px;
      color: #1a1a1a;
      line-height: 24px;
      margin-top: 24px;
    }
    .van-checkbox {
      align-items: start;
      .van-checkbox__icon {
        margin-top: 3px;
        .van-badge__wrapper {
          border-radius: 2px;
        }
      }
    }
    .agree {
      font-size: 14px;
      .agree-text {
        color: #90d12e;
      }
    }
    .van-uploader,
    .van-uploader__input-wrapper {
      width: 100%;
      .van-uploader__preview-image {
        height: 216px !important;
        object-fit: cover;
      }
    }
    .van-button {
      border-radius: 12px;
    }
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }
  }
  .verify {
    color: #f82207;
  }
  .van-field {
    background-color: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ebebeb;
    margin-bottom: 12px;
    padding: 16px;
    .van-field__control {
      font-size: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;

      &::placeholder {
        color: #999999;
      }
    }
  }
  .van-tabs {
    width: 60%;
  }
  .van-tabs__nav {
    background-color: transparent;
    height: auto;
    .van-tabs__line {
      display: none;
    }
    .van-tab:first-child {
      border-right: 1px solid #bdbdbd;
    }
    .van-tab__text {
      font-size: 18px;
    }
  }
  .arrow {
    width: 16px;
    height: 16px;
  }
  .phone {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #1a1a1a;
    margin: 12px 0px 20px 14px;
  }
  .login-form {
    padding: 0 22px;
  }
}
.Forgot {
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 20px;
}
.register {
  text-align: center;
  color: #90d12e;
}
.AddressList {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-size: 16px;
  background-color: #fff;
  // margin-bottom: 6px;
  img {
    width: 20px;
  }
}
.register-dialog {
  padding: 24px;
}
.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;
  span {
    color: #3491fa;
  }
}
.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }
  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }
  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>
